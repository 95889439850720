import * as React from 'react';
import { Tilbud } from '~/flyt/hovedflyt/tilbud/Tilbud';
import {setLogOrgnr, setLogTilbudId} from '~/utils/fetch';

export interface TilbudContextProps {
    tilbud: Tilbud | undefined;
    setTilbud: (tilbud: Tilbud) => void;
    tilbudId: string | undefined;
    setTilbudId: (tilbudId: string) => void;
}

export const TilbudContext = React.createContext<TilbudContextProps>({
    tilbud: undefined as Tilbud | undefined,
    setTilbud: (_tilbud: Tilbud) => {},
    tilbudId: undefined as string | undefined,
    setTilbudId: (_tilbudId: string) => {}
});

const TilbudContextProvider = ({children}) => {
    const [tilbud, setTilbudInternal] = React.useState<Tilbud>();
    const [tilbudId, setTilbudIdInternal] = React.useState<string>();

    const setTilbud = (newTilbud: Tilbud) => {
        setLogOrgnr(newTilbud.orgnr);
        setTilbudInternal(newTilbud);
    };

    const setTilbudId = tilbudId => {
        setLogTilbudId(tilbudId);
        setTilbudIdInternal(tilbudId);
    }
    return (
        <TilbudContext.Provider value={{tilbud, setTilbud, tilbudId, setTilbudId}}>
            {children}
        </TilbudContext.Provider>
    );
};
export default TilbudContextProvider;
