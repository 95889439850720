import React from 'react';
import {formatFodselsnummer} from '@sb1/ffe-formatters';
import {Ansatt} from '~/flyt/hovedflyt/tilbud/Tilbud';
import {formaterBelop} from "~/utils/uiUtils";
import LegacyTable from "~/components/felles/LegacyTable";

export interface Params {
    ansatte: Ansatt[];
}

const columns = [
    {
        key: 'personInfo', header: 'Navn', cellRender: (_value, _col, props) => {
            return <>{props.cells.fornavn} {props.cells.etternavn}</>
        }
    },
    {
        key: 'fnr', header: 'Fødselsnummer', cellRender: (value) => {
            return <>{formatFodselsnummer(value)}</>
        }
    },
    {
        key: 'lonn', header: 'Årslønn', cellRender: (value) => {
            return <>{formaterBelop(value)}</>
        }
    },
    {
        key: 'stillingspros', header: 'Stillingsprosent', cellRender: (value) => {
            return <>{value}%</>
        }
    },
    {
        key: 'arbeidsdyktig', header: 'Arbeidsdyktig', cellRender: (value) => {
            return <>{value ? 'Ja' : 'Nei'}</>
        }
    },
    {
        key: 'eier', header: 'Eier over 10%', cellRender: (value) => {
            return <>{value ? 'Ja' : 'Nei'}</>
        }
    }
];

const AnsatteTabell: React.FunctionComponent<Params> = ({ ansatte }) => {
    return <LegacyTable columns={columns} data={ansatte} />
}
export default AnsatteTabell;
