import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router';
import Ansattinformasjon from '~/flyt/hovedflyt/ansatte/Ansattinformasjon';
import {FormSubmitStatus, Skjemadata, trackFormStepStarted} from '@sparebank1/pensjon-bm-analytics';
import {FormContext} from '~/Routing';
import {useErrorHandler} from '~/components/felles/ErrorHandler';
import {oppdaterTilbud} from '~/flyt/hovedflyt/tilbud/TilbudService';
import useTilbud from '~/flyt/hovedflyt/tilbud/useTilbud';
import {useTrackPage} from '~/components/tracking/useTrackPage';

export const SKJEMA: Skjemadata = {navn: 'Endre ansattinformasjon', type: '', skjematype: 'KSMB'};
export const steg = {
    FYLL_INN_ANSATTE: {navn: 'Fyll inn ansatte', nummer: 1},
    SJEKK_DSF: {navn: 'Sjekk folkeregisteret', nummer: 2},
    REBEREGN_PRIS: {navn: 'Reberegn pris', nummer: 3},
    OPPDATER_TILBUD: {navn: 'Oppdater tilbud', nummer: 4}
};

const EndreAnsattinformasjon: React.FunctionComponent = () => {

    useTrackPage(SKJEMA, steg.FYLL_INN_ANSATTE);

    const {tilbudId, tilbud} = useTilbud();
    const [beregner, setBeregner] = useState<boolean>(false);
    const formContext = useContext(FormContext);
    const {catchError} = useErrorHandler();
    const navigate = useNavigate();

    const onFinish = async () => {
        try {
            trackFormStepStarted(steg.OPPDATER_TILBUD);
            const resultRespons = await oppdaterTilbud(tilbudId, tilbud);
            tilbud.pris = resultRespons.pris;
            formContext.setFormStatus(FormSubmitStatus.SUCCESS);
            formContext.setFerdig(true);
            navigate(`..`);
        } catch(e: any) {
            setBeregner(false);
            catchError(e);
        }
    };

    return <Ansattinformasjon tilbud={tilbud} knappetekst="Oppdater" steg={steg.SJEKK_DSF} beregner={beregner} setBeregner={setBeregner} onFinish={onFinish} flytting={false}/>
}
export default EndreAnsattinformasjon;
