import 'regenerator-runtime/runtime';
import React, { useContext } from 'react';
import ChatWindow from './chat-window/ChatWindow';
import { useInnloggetBruker } from '~/components/felles/InnloggetBruker';
import { get } from '~/utils/fetch';
import { useSettings } from '~/components/felles/Settings';
import { erInnenforApningstidene } from '~/components/felles/apningstiderService';
import { TilbudContext } from '~/flyt/hovedflyt/tilbud/TilbudContext';

const loadSamlToken = orgnr => () => {
    return get('/api/chat/saml', orgnr ? { orgnr } : {}) as Promise<any>;
};

interface Props {
    chatWindowRef: React.RefObject<ChatWindow | null>;
}

const Chat: React.FunctionComponent<Props> = ({ chatWindowRef }) => {
    const { innlogget } = useInnloggetBruker();
    const settings = useSettings();
    const { tilbud } = useContext(TilbudContext);
    return <ChatWindow
        isLoggedIn={innlogget}
        chatEnabled={true}
        chatIsOpen={erInnenforApningstidene(settings)}
        loadSamlToken={loadSamlToken(tilbud?.orgnr)}
        loggedInChatUrl={settings.chat_secure_url}
        publicChatUrl={settings.chat_public_url}
        ref={chatWindowRef}
    />
};

export default Chat;
