import React, {useContext, useEffect} from 'react';
import {useSettings} from '~/components/felles/Settings';
import {
    AnalyticsConfig,
    initAdobe,
    isInitComplete,
    setStatus,
    setUserLoginstatus
} from '@sparebank1/pensjon-bm-analytics';
import {useInnloggetBruker} from '~/components/felles/InnloggetBruker';
import {TilbudContext} from '~/flyt/hovedflyt/tilbud/TilbudContext';

const getAnalyticsConfig = (debugEnabled: string): AnalyticsConfig => {
    const adobeInitConfig: AnalyticsConfig = {
        analyticsScriptUrl: '/open/adobeanalytics',
        portal: 'ksmb',
        debugEnabled: Boolean(debugEnabled),
        userLoginstatus: 'ikke innlogget'
    };
    return adobeInitConfig;
};

const TrackingSetup: React.FunctionComponent = () => {
    const { innlogget } = useInnloggetBruker();
    const { adobe_analytics_enabled, adobe_analytics_debug_enabled } = useSettings();
    const tilbudContext = useContext(TilbudContext);

    useEffect(() => {
        if (!isInitComplete()) {
            initAdobe(getAnalyticsConfig(adobe_analytics_debug_enabled),
                Boolean(adobe_analytics_enabled));
        }
    }, [adobe_analytics_enabled, adobe_analytics_debug_enabled]);

    useEffect(() => {
        setUserLoginstatus(innlogget ? 'innlogget' : 'ikke innlogget');
    }, [innlogget]);

    useEffect(() => {
        if (tilbudContext.tilbudId) {
            setStatus('har tilbud');
        } else {
            setStatus('har ikke tilbud');
        }
    }, [tilbudContext.tilbud]); // eslint-disable-line react-hooks/exhaustive-deps

    return <></>;
};

export default TrackingSetup;
