import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {Checkbox, Input, InputGroup} from "@sb1/ffe-form-react";
import {ActionButton, ButtonGroup} from "@sb1/ffe-buttons-react";
import TrackedButtons from "~/components/tracking/TrackedButtons";
import {requiredMelding} from "~/flyt/hovedflyt/produkt/validering";
import {Heading2, Paragraph} from "@sb1/ffe-core-react";
import {BulletList, BulletListItem} from "@sb1/ffe-lists-react";
import {post} from "~/utils/fetch";
import {useSettings} from "~/components/felles/Settings";
import RadioSwitchJaNei from "~/components/felles/RadioSwitchJaNei";
import {useErrorHandler} from "~/components/felles/ErrorHandler";
import epost from "~/components/validering/epost";
import {z} from "zod";
import {zString} from "~/components/validering/validering";
import {zodResolver} from "@hookform/resolvers/zod";
import {SystemMessage} from "@sb1/ffe-messages-react";
import FlyttesFraSelskap from "~/components/flytt/FlyttesFraSelskap";
import Epost from "~/components/felles/chat-window/Epost";

interface Props {
    orgnr: string;
    antallAnsatte: number;
}

const validering = z.object({
    flyttesFraSelskap: zString(requiredMelding),
    dagligLederInnehaverEllerStyreleder: z.boolean({required_error: requiredMelding}),
    epost: epost(true),
    akseptVilkar: z.boolean()
}).superRefine(({dagligLederInnehaverEllerStyreleder, akseptVilkar}, ctx) => {
    if (dagligLederInnehaverEllerStyreleder && !akseptVilkar) {
        ctx.addIssue({code: z.ZodIssueCode.custom, path: ['akseptVilkar'], message: 'Vennligst bekreft at du har lest og forstått forutsetningene for kjøpet'});
    }
})

type FormInput = z.infer<typeof validering>;

const FlyttefullmaktSkjema: React.FunctionComponent<Props> = ({orgnr, antallAnsatte}) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ bekreftet, setBekreftet ] = useState(false);
    const { kfb_url } = useSettings();
    const {catchError} = useErrorHandler();

    const { register, handleSubmit, formState: { errors, isSubmitted }, watch, setValue } = useForm<FormInput>({
        resolver: zodResolver(validering),
        defaultValues: { dagligLederInnehaverEllerStyreleder: true }
    });

    const bestill = async ({epost, flyttesFraSelskap, dagligLederInnehaverEllerStyreleder} : FormInput) => {
        try {
            setIsLoading(true);
            if (dagligLederInnehaverEllerStyreleder) {
                await post('/api/flyttefullmakt/bestill', {orgnr, antallAnsatte, epost, flyttesFraSelskap});
                window.location.href = `${kfb_url}/kfb/frontend/kundeklient/${orgnr}/tilbud`;
            } else {
                await post('/api/flyttefullmakt/bestill_epost', {orgnr, antallAnsatte, epost, flyttesFraSelskap});
                setBekreftet(true);
            }
        } catch (error: any) {
            catchError(error);
        } finally {
            setIsLoading(false);
        }
    }

    const dagligLederInnehaverEllerStyreleder = watch('dagligLederInnehaverEllerStyreleder');

    return <form onSubmit={handleSubmit(bestill)}>
        <Heading2 lookLike={3}>Siste opplysninger</Heading2>
        <FlyttesFraSelskap
            flyttesFraSelskap={watch("flyttesFraSelskap")}
            setFlyttesFraSelskap={value => setValue("flyttesFraSelskap", value, {shouldValidate: isSubmitted})}
            fieldMessage={errors.flyttesFraSelskap?.message}
        />
        <RadioSwitchJaNei
            name="dagligLederInnehaverEllerStyreleder"
            selectedValue={dagligLederInnehaverEllerStyreleder}
            setValue={setValue}
            label="Er du daglig leder, innehaver eller styreleder med eierskap i bedriften?"
            fieldMessage={errors?.dagligLederInnehaverEllerStyreleder?.message}
        />
        {dagligLederInnehaverEllerStyreleder ?
            <div>
                <Epost
                    label="E-post for kvittering"
                    epost={watch("epost")}
                    setEpost={value => setValue("epost", value, {shouldValidate: isSubmitted})}
                    fieldMessage={errors.epost?.message}
                />
                <Heading2 lookLike={3}>
                    Forutsetninger for kjøp
                </Heading2>
                Ved å signere bekrefter dere følgende:
                <BulletList>
                    <BulletListItem>
                        At opplysningene som er oppgitt er korrekte.
                    </BulletListItem>
                    <BulletListItem>
                        Prisen kan avvike fra dagens avtale.
                    </BulletListItem>
                    {antallAnsatte >= 15 ?
                        <BulletListItem>
                            At styringsgruppe er informert om flyttingen.
                        </BulletListItem>
                        : null
                    }
                    <BulletListItem>
                        Du har lest og forsått vilkårene for avtalen.
                    </BulletListItem>
                </BulletList>
                <Paragraph>
                    For å kjøpe produktene må du logge inn med BankID. Slik bekrefter vi din rolle i bedriften mot Brønnøysundregisterne.
                </Paragraph>
                <InputGroup fieldMessage={errors?.akseptVilkar?.message}>
                    {inputProps =>
                        <Checkbox {...inputProps} {...register("akseptVilkar")}>
                            Jeg bekrefter å ha lest og at jeg er inneforstått med forutsetningene for kjøp
                        </Checkbox>
                    }
                </InputGroup>
                <ButtonGroup thin={true} ariaLabel="Knapp for å gå til signering">
                    <TrackedButtons steg={'flytting123'} knappNavn={'tilSignering'}>
                        <ActionButton isLoading={isLoading} type={'submit'}>Gå til signering</ActionButton>
                    </TrackedButtons>
                </ButtonGroup>
            </div>
            :
            <>
                <InputGroup
                    label="E-post til daglig leder, innehaver eller styrer med eierskap i bedriften"
                    description={'Personen vil få en e-post med en lenke som lar de se over tilbudet og gå til signering.'}
                    fieldMessage={errors?.epost?.message}
                >
                    <Input
                        type="email"
                        {...register("epost")}
                        className="ksmb-input-field ksmb-input-field--xl"
                        autoComplete="on"
                    />
                </InputGroup>
                {bekreftet ?
                    <SystemMessage type={"success"}>E-post er sendt. Personen vil motta en lenke hvor de kan se over tilbudet og signere.</SystemMessage>
                    :
                    <ButtonGroup thin={true} ariaLabel="Knapp for å sende tilbud på e-post">
                        <TrackedButtons steg={'flytting123'} knappNavn={'sendEpost'}>
                            <ActionButton isLoading={isLoading} type={'submit'}>Send tilbud på e-post</ActionButton>
                        </TrackedButtons>
                    </ButtonGroup>
                }
            </>
        }
    </form>
}
export default FlyttefullmaktSkjema;
