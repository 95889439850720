import {get, post, put} from '~/utils/fetch';
import { Tilbud } from '~/flyt/hovedflyt/tilbud/Tilbud';

export interface Pris {
    pris: number;
    forbehold: string[];
}

export async function hentTilbud(id: string) {
    const response = await get(`/api/tilbud/${id}`);
    return response as Tilbud;
}

export async function opprettTilbud(tilbud: Tilbud) {
    const response = await post('/api/tilbud', tilbud);
    return response as unknown as string;
}

export async function oppdaterTilbud(id: string, tilbud: Tilbud): Promise<Pris> {
    const response = await put(`/api/tilbud/${id}`, tilbud);
    return response as Pris;
}

export async function harSigneringsrett(tilbudId: string) {
    const response = await post('/api/signeringsrett', {id: tilbudId});
    return response as unknown as void;
}

export async function sendTilbudsEpost(tilbudId: string) {
    const response = await post('/api/tilbudsepost', {id: tilbudId});
    return response;
}
