import {useContext} from 'react';
import {TilbudContext} from '~/flyt/hovedflyt/tilbud/TilbudContext';
import {useErrorHandler} from '~/components/felles/ErrorHandler';

export default function useTilbud() {
    const {tilbudId, tilbud} = useContext(TilbudContext);
    const {setFeilmelding} = useErrorHandler();
    if (!tilbudId || !tilbud) {
        setFeilmelding('Kunne ikke laste tilbud');
        throw new Error();
    } else { return {tilbudId, tilbud}; }
}
