import React, {useEffect} from 'react';
import {Heading1, Paragraph} from '@sb1/ffe-core-react';
import {Grid, GridCol, GridRow} from '@sb1/ffe-grid-react';
import {Org} from '~/components/orgvelger/OrgSokService';
import {trackPage} from '@sparebank1/pensjon-bm-analytics';
import {Tilbud} from '~/flyt/hovedflyt/tilbud/Tilbud';
import {finnInitiellePaakrevdeVerdier} from '~/flyt/hovedflyt/tilbud/regler';
import {useNavigate} from "react-router";
import OrgVelger from "~/components/orgvelger/OrgVelger";

export const PAGE = 'FORSIDEN: Orgsøk og antall ansatte';

export const initTilbud = (org: Org, omkostningsmodell: string): Tilbud => {
    const nyttTilbud: Tilbud = {
        orgnr: org.orgnr,
        orgnavn: org.navn,
        ansatte: [],
        forbehold: [],
        epost: '',
        status: 'DRAFT',
        uforeDekning: null,
        ...finnInitiellePaakrevdeVerdier(),
        omkostningsmodell
    };
    return nyttTilbud;
};

const Steg1: React.FunctionComponent = () => {
    const navigate = useNavigate();

    useEffect(() => {
        trackPage(PAGE);
    }, []);

    const velgOrgnr = (orgnr: string) => {
        navigate(`/org/${orgnr}`);
    }

    return <Grid className="ksmb-steg-1">
        <GridRow>
            <GridCol center={true} className='ksmb-grid-col--padding-bottom'>
                <Heading1 className="ksmb-header--center">Innskuddspensjon</Heading1>
            </GridCol>
        </GridRow>
        <GridRow>
            <GridCol lg={{offset: 3, cols: 6}} className='ksmb-grid-col--padding-bottom'>
                <Paragraph lead={true} className="ffe-body-paragraph--text-center">
                    Her kan du enkelt beregne pris for å etablere eller flytte bedriftens innskuddspensjon til oss.
                </Paragraph>
            </GridCol>
        </GridRow>
        <OrgVelger velgOrgnr={velgOrgnr} />
    </Grid>
};

export default Steg1;
