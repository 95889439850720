import React, {useState} from 'react';
import {Heading2, Paragraph} from '@sb1/ffe-core-react';
import {Input, InputGroup} from '@sb1/ffe-form-react';
import {ActionButton, ButtonGroup, SecondaryButton} from '@sb1/ffe-buttons-react';
import {GridCol, GridRow} from '@sb1/ffe-grid-react';
import TrackedButtons from '~/components/tracking/TrackedButtons';
import {useForm} from "react-hook-form";
import {requiredMelding} from "~/flyt/hovedflyt/produkt/validering";
import RadioSwitchJaNei from "~/components/felles/RadioSwitchJaNei";
import {useNavigate, useParams} from "react-router";
import useOrgnr from "~/components/orgvelger/useOrgnr";
import {Spinner} from "@sb1/ffe-spinner-react";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import epost from "~/components/validering/epost";
import {post} from "~/utils/fetch";
import {useErrorHandler} from "~/components/felles/ErrorHandler";
import {ContextMessage} from "@sb1/ffe-messages-react";
import FlyttesFraSelskap from "~/components/flytt/FlyttesFraSelskap";
import {zString} from "~/components/validering/validering";
import Epost from "~/components/felles/chat-window/Epost";

const validering = z.object({
    avtalenummer: z.coerce.number({invalid_type_error: "Må være et tall"}).optional(),
    dagligLederInnehaverEllerStyreleder: z.boolean({required_error: requiredMelding}),
    flyttesFraSelskap: zString(requiredMelding),
    epost: epost(false),
}).superRefine(({dagligLederInnehaverEllerStyreleder, epost}, ctx) => {
    if (!dagligLederInnehaverEllerStyreleder && epost === '') {
        ctx.addIssue({code: z.ZodIssueCode.custom, path: ['epost'], message: requiredMelding});
    }
})

const steg = {
    INFOFULLMAKT_BESTILL: {navn: 'Bestilling av informasjonsinnhentingsfullmakt', nummer: 3}
}

type FormInput = z.infer<typeof validering>;

type Params = 'orgnr';

const Bestill: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const {orgnr} = useParams<Params>();
    const {org} = useOrgnr(orgnr);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ suksess, setSuksess ] = useState<string>();
    const {catchError} = useErrorHandler();

    const { handleSubmit, register, formState: { errors, isSubmitted }, watch, setValue } = useForm<FormInput>({
        resolver: zodResolver(validering),
        defaultValues: {epost: ''}
    });

    const dagligLederInnehaverEllerStyreleder = watch('dagligLederInnehaverEllerStyreleder');
    const epost = watch("epost");

    if (dagligLederInnehaverEllerStyreleder && !!epost) {
        setValue('epost', '');
    }

    const bestill = async (values: FormInput) => {
        try {
            setIsLoading(true);
            const { avtalenummer, epost, dagligLederInnehaverEllerStyreleder, flyttesFraSelskap } = values;
            const tilbudId = await post("/api/infofullmakt/bestill", {orgnr, avtalenummer, epost, flyttesFraSelskap});
            if (dagligLederInnehaverEllerStyreleder) {
                navigate(`/infofullmakt/signering/${orgnr}/${tilbudId}`);
            } else {
                setSuksess(`E-post med lenke til fullmakten ble sendt til ${epost}`);
            }
        } catch (error: any) {
            catchError(error);
        } finally {
            setIsLoading(false);
        }
    }

    return <>
        <GridRow padding={"lg"}>
            {org ?
                <GridCol lg={{offset:2, cols: 6}} md={{offset: 2, cols: 8}}>
                    <Heading2 lookLike={3}>{org.navn} {org.orgnr}</Heading2>
                    <form onSubmit={handleSubmit(bestill)}>
                        <FlyttesFraSelskap
                            flyttesFraSelskap={watch("flyttesFraSelskap")}
                            setFlyttesFraSelskap={value => setValue("flyttesFraSelskap", value, {shouldValidate: isSubmitted})}
                            fieldMessage={errors.flyttesFraSelskap?.message}
                        />

                        <InputGroup label="Avtalenummer (valgfritt)" fieldMessage={errors?.avtalenummer?.message}>
                            <Input
                                {...register("avtalenummer")}
                                className="ksmb-input-field ksmb-input-field--md"
                                autoComplete="on"
                            />
                        </InputGroup>

                        <RadioSwitchJaNei
                            name="dagligLederInnehaverEllerStyreleder"
                            selectedValue={dagligLederInnehaverEllerStyreleder}
                            setValue={setValue}
                            label="Er du daglig leder, innehaver eller styreleder med eierskap i bedriften?"
                            fieldMessage={errors?.dagligLederInnehaverEllerStyreleder?.message}
                        />

                        {dagligLederInnehaverEllerStyreleder === false ?
                            <>
                                <Paragraph>
                                    For at vi skal kunne innhente opplysninger om pensjonsavtalen til bedriften
                                    må daglig leder eller innehaver logge seg inn og signere fullmakten.
                                    Oppgi e-post til daglig leder eller innehaver så sendes en lenke til fullmakten.
                                </Paragraph>
                                <Epost
                                    epost={watch("epost")}
                                    setEpost={value => setValue("epost", value, {shouldValidate: isSubmitted})}
                                    fieldMessage={errors.epost?.message}
                                />
                            </>
                            : null
                        }
                        {dagligLederInnehaverEllerStyreleder === true ?
                            <Paragraph>
                                For at vi skal kunne innhente opplysninger om pensjonsavtalen til bedriften må du logge inn med BankID.
                                Du vil da kunne se over før du kan signere fullmakten.
                            </Paragraph>
                            : null
                        }
                        {suksess ?
                            <ContextMessage type={"success"}>
                                {suksess}
                            </ContextMessage>
                            :
                            <ButtonGroup className="ksmb-button-group--md" ariaLabel="Knapper for å gå videre eller velge annen bedrift">
                                <TrackedButtons steg={steg.INFOFULLMAKT_BESTILL.navn} knappNavn={['Bestill', 'Velg annen bedrift']}>
                                    <ActionButton type="submit" isLoading={isLoading}>
                                        { dagligLederInnehaverEllerStyreleder === true ?
                                            'Logg inn'
                                            : dagligLederInnehaverEllerStyreleder === false ?
                                                'Send e-post'
                                                : 'Gå videre'
                                        }
                                    </ActionButton>
                                    <SecondaryButton type="button" onClick={() => navigate('/')}>
                                        Velg annen bedrift
                                    </SecondaryButton>
                                </TrackedButtons>
                            </ButtonGroup>
                        }
                    </form>
                </GridCol>
                :
                <GridCol center={true}>
                    <Spinner large={true} className="ksmb-spinner--lg" loadingText="Henter informasjon om bedriften..." />
                </GridCol>
            }
        </GridRow>
    </>
};
export default Bestill;
