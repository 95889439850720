import React from 'react';
import {sub} from "date-fns";
import {formatDate, forsteINesteManed} from '~/utils/datoUtil';

export const inkrementerendeOptions = (start: number, slutt: number): React.ReactNode[] => {
    const options: React.ReactNode[] = [];
    for (let i = start; i <= slutt; i++) {
        options.push(<option key={i} value={i}>{i} %</option>);
    }
    return options;
};

export const andelAvLonnOptions = (): React.ReactNode[] => inkrementerendeOptions(2, 7);

export const tilleggssparingOptions = (): React.ReactNode[] => {
    const options: React.ReactNode[] = inkrementerendeOptions(0, 18);
    options.push(<option key={'18.1'} value={18.1}>18,1 %</option>);
    return options;
};

export const startdatoOptions = (): React.ReactNode[] => {
    const options: React.ReactNode[] = [];
    const nesteManed = forsteINesteManed();
    for (let i = 0; i <= 19; i++) {
        const start = sub(nesteManed, { months: i});
        options.push(<option key={i} value={formatDate(start)}>{start.toLocaleDateString('no-NB', {year: 'numeric', month: 'long'})}</option>);
    }
    return options;
};
