import React from 'react';
import {useContext} from 'react';
import {Spinner} from '@sb1/ffe-spinner-react';
import {get} from '~/utils/fetch';

interface FlytteSelskap {
    id: string;
    navn: string;
}

export interface SettingsType {
    kontakt_telefon: string;
    kontakt_apner: string;
    kontakt_stenger_vinter: string;
    kontakt_stenger_sommer: string;
    login_url: string;
    chat_public_url: string;
    chat_secure_url: string;
    kfb_url: string;
    omkostningsmodell: string;
    adobe_analytics_enabled: string;
    adobe_analytics_debug_enabled: string;
    gverdi: number;
    flytteFraSelskaper: FlytteSelskap[];
}

export const SettingsContext = React.createContext<SettingsType | null>(null);

export const useSettings = () => {
    const settings = useContext(SettingsContext);
    if (!settings) {
        throw new Error('Mangler settings');
    }
    return settings;
}

const Settings = ({children}) => {
    const [settings, setSettings] = React.useState<any>();
    const loadSettings = () => {
        get('/api/settings').then(settingsRespons => setSettings(settingsRespons)).catch(() => undefined);
        return;
    };
    if (settings === null || settings === undefined) {
        loadSettings();
        return <div className="ksmb-spinner-container--fullscreen">
            <Spinner large={true} loadingText="Vennligst vent..."/>
        </div>
    }
    return (
        <SettingsContext.Provider value={settings}>
            {children}
        </SettingsContext.Provider>
    );
};
export default Settings;
