import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {DividerLine, Heading2, Paragraph} from '@sb1/ffe-core-react';
import {Input, InputGroup, Tooltip} from '@sb1/ffe-form-react';
import {Dropdown} from "@sb1/ffe-dropdown-react";
import {ActionButton, ButtonGroup} from '@sb1/ffe-buttons-react';
import {Collapse} from '@sb1/ffe-collapse-react';

import {valideringProduktdetaljer} from '~/flyt/hovedflyt/produkt/validering';
import TrackedButtons from '~/components/tracking/TrackedButtons';
import {Hjelpetekst} from '~/flyt/hovedflyt/produkt/Hjelpetekst';
import * as hjelpetekst from '~/flyt/hovedflyt/produkt/hjelpetekster';
import {Pris} from '~/flyt/hovedflyt/tilbud/TilbudService';
import {
    andelAvLonnOptions,
    startdatoOptions,
    tilleggssparingOptions
} from '~/flyt/hovedflyt/produkt/optionsForNedtrekkslister';
import {Ansatt} from '~/flyt/hovedflyt/tilbud/Tilbud';
import {Forbehold} from '~/flyt/hovedflyt/produkt/Forbehold';
import {SjekkPrisdrivendeVerdier} from '~/flyt/hovedflyt/produkt/SjekkPrisdrivendeVerdier';
import {flyttedato, formatDate, forsteINesteManed} from '~/utils/datoUtil';
import RadioSwitchJaNei from "~/components/felles/RadioSwitchJaNei";
import {zodResolver} from "@hookform/resolvers/zod";
import {formaterBelopKr} from "~/utils/uiUtils";
import FlyttesFraSelskap from "~/components/flytt/FlyttesFraSelskap";

export interface ProduktdetaljerInput {
    lavSats?: number;
    hoySats?: number;
    uforeDekning?: boolean | null;
    flyttesFraSelskap?: string;
    flyttesFraAvtalenummer?: string;
    startdato?: string;
    dagligLederInnehaverEllerStyreleder?: boolean | null;
}

export interface ProduktdetaljerSkjemaProps {
    initiellProduktdetaljerInput: ProduktdetaljerInput;
    ansatte: Ansatt[];
    endreModus: boolean;
    flytting: boolean;
    visHoysats: boolean;
    visUfore: boolean;
    feilmelding?: string | null;
    loading: boolean;
    pris: Pris;
    gverdi: number;
    beregner: boolean;
    onChange: (values: ProduktdetaljerInput) => void;
    utforGaVidere: (values: ProduktdetaljerInput) => void;
    setFeilmelding: (feilmelding: string) => void;
}

const ProduktdetaljerSkjema: React.FunctionComponent<ProduktdetaljerSkjemaProps> =
    ({
        initiellProduktdetaljerInput,
        endreModus,
        flytting,
        visHoysats,
        visUfore,
        onChange,
        utforGaVidere,
        setFeilmelding,
        loading,
        pris,
        gverdi
    }) => {

        const [visHjelpAndelAvLonn, setVisHjelpAndelAvLonn] = useState<boolean>(false);
        const [visHjelpTilleggssparing, setVisHjelpTilleggssparing] = useState<boolean>(false);
        const [visHjelpUforeDekning, setVisHjelpUforeDekning] = useState<boolean>(false);
        const [visHjelpStartdato, setVisHjelpStartdato] = useState<boolean>(false);

        const { register, handleSubmit, formState: { errors, isSubmitted }, watch, setValue } = useForm<ProduktdetaljerInput>({
            resolver: zodResolver(valideringProduktdetaljer(endreModus, visUfore, flytting)),
            defaultValues: initiellProduktdetaljerInput
        });

        useEffect(() => {
            if (Object.keys(errors).length > 0) {
                setFeilmelding('Valideringsfeil i skjemaet');
            }
        }, [errors, setFeilmelding]);

        const innslagspunktTilleggssparing = formaterBelopKr(Math.round(gverdi * 7.1));

        const dagligLederInnehaverEllerStyreleder = watch('dagligLederInnehaverEllerStyreleder');

        return (
            <form onSubmit={handleSubmit(utforGaVidere)}>
                <InputGroup
                    label="Hvor stor andel av lønnen skal spares i pensjon til de ansatte?"
                    tooltip={
                        <Tooltip onClick={
                            () => setVisHjelpAndelAvLonn(!visHjelpAndelAvLonn)
                        }></Tooltip>
                    }
                    fieldMessage={errors?.lavSats?.message}>
                    {inputProps => (
                        <>
                            <Hjelpetekst vis={visHjelpAndelAvLonn} tekst={hjelpetekst.lavSats} gverdi={gverdi} />
                            <Dropdown
                                name={'lavSats'}
                                defaultValue={watch('lavSats')}
                                onChange={e => setValue('lavSats', parseInt(e.target.value))}
                                {...inputProps}
                                className="ksmb-input-field ksmb-input-field--xs
                                    ksmb-produktspesifikasjon-input-group--extra-margin-top">
                                {andelAvLonnOptions()}
                            </Dropdown>
                        </>
                    )}
                </InputGroup>

                {visHoysats &&
                    <InputGroup
                        data-testid="hoySats"
                        label={`Vil du inkludere tilleggsparing for ansatte med lønn over 7,1 G (ca. ${
                            innslagspunktTilleggssparing})?`}
                        tooltip={
                            <Tooltip onClick={
                                () => setVisHjelpTilleggssparing(!visHjelpTilleggssparing)
                            }></Tooltip>
                        }
                        fieldMessage={errors?.hoySats?.message}>
                        {inputProps => (
                            <>
                                <Hjelpetekst vis={visHjelpTilleggssparing} tekst={hjelpetekst.hoySats(innslagspunktTilleggssparing)} gverdi={gverdi} />
                                <Dropdown
                                    name={'hoySats'}
                                    defaultValue={watch('hoySats')}
                                    onChange={e => setValue('hoySats', parseInt(e.target.value))}
                                    {...inputProps}
                                    className="ksmb-input-field ksmb-input-field--xs
                                        ksmb-produktspesifikasjon-input-group--extra-margin-top">
                                    {tilleggssparingOptions()}
                                </Dropdown>
                            </>
                        )}
                    </InputGroup>
                }

                {visUfore &&
                    <RadioSwitchJaNei
                        name="uforeDekning"
                        selectedValue={watch('uforeDekning')}
                        setValue={setValue}
                        data-testid="uforeDekning"
                        label="Vil du inkludere uførepensjon for bedriftens ansatte?"
                        tooltip={<Tooltip onClick={() => setVisHjelpUforeDekning(!visHjelpUforeDekning)}></Tooltip>}
                        fieldMessage={errors?.uforeDekning?.message}
                    >
                        <Hjelpetekst vis={visHjelpUforeDekning} tekst={hjelpetekst.uforeDekning} />
                    </RadioSwitchJaNei>
                }

                {flytting ?
                    <fieldset className="ffe-fieldset ksmb-produktspesifikasjon__fieldset ksmb-produktspesifikasjon__fieldset--bg-sand">
                        <FlyttesFraSelskap
                            flyttesFraSelskap={watch("flyttesFraSelskap")}
                            setFlyttesFraSelskap={value => setValue("flyttesFraSelskap", value, {shouldValidate: isSubmitted})}
                            fieldMessage={errors.flyttesFraSelskap?.message}
                        />
                        <InputGroup
                            label="Avtalenummer (valgfritt)"
                            fieldMessage={errors?.flyttesFraAvtalenummer?.message}>
                            <Input {...register("flyttesFraAvtalenummer")}
                                className="ksmb-input-field ksmb-input-field--sm"
                            />
                        </InputGroup>

                        <Heading2 lookLike={4}>Startdato beregnes til {flyttedato()}</Heading2>
                        <Paragraph>
                            {`Regler for flytting av pensjonsavtaler innebærer at det tar opp til 3 måneder før avtalen er flyttet. Startdato hos oss forutsetter at du signerer avtalen før ${formatDate(forsteINesteManed())}.`}
                        </Paragraph>
                    </fieldset>
                    :
                    <InputGroup
                        label="Fra hvilken måned skal sparingen starte?"
                        tooltip={
                            <Tooltip onClick={
                                () => setVisHjelpStartdato(!visHjelpStartdato)
                            }></Tooltip>
                        }
                        fieldMessage={errors?.startdato?.message}>
                        {inputProps => (
                            <>
                                <Hjelpetekst vis={visHjelpStartdato} tekst={hjelpetekst.startdato} />
                                <Dropdown name="startdato"
                                    defaultValue={initiellProduktdetaljerInput.startdato}
                                    onChange={e => setValue('startdato', e.target.value)}
                                    {...inputProps}
                                    className="ksmb-input-field ksmb-input-field--md
                                        ksmb-produktspesifikasjon-input-group--extra-margin-top ksmb-text--capitalize">
                                    {startdatoOptions()}
                                </Dropdown>
                            </>
                        )}
                    </InputGroup>
                }

                <Forbehold forbehold={pris.forbehold} />
                <DividerLine />

                { !endreModus &&
                    <>
                        <RadioSwitchJaNei
                            name="dagligLederInnehaverEllerStyreleder"
                            selectedValue={watch('dagligLederInnehaverEllerStyreleder')}
                            setValue={setValue}
                            label="Er du daglig leder, innehaver eller styreleder med eierskap i bedriften?"
                            fieldMessage={errors?.dagligLederInnehaverEllerStyreleder?.message}>
                        </RadioSwitchJaNei>

                        <Collapse isOpen={dagligLederInnehaverEllerStyreleder !== null}
                            className="ksmb-transistions-collapse-expand">
                            {dagligLederInnehaverEllerStyreleder === true ?
                                <Paragraph>
                                    {hjelpetekst.infoTekstDagligLeder}
                                </Paragraph>
                                :
                                <Paragraph>
                                    {hjelpetekst.infoTekstIkkeDagligLeder}
                                </Paragraph>
                            }
                        </Collapse>
                    </>
                }

                <ButtonGroup className="ksmb-button-group--md" ariaLabel="Knapp for å gå videre til neste steg">
                    <TrackedButtons knappNavn="produktspesifikasjon: Gå videre">
                        <ActionButton type="submit"
                            isLoading={loading}
                            ariaLoadingMessage="Vennligst vent"
                            data-testid="produktdetaljer-ga-videre">
                            {endreModus ? 'Oppdater' : dagligLederInnehaverEllerStyreleder ? 'Logg inn' : 'Gå videre'}
                        </ActionButton>
                    </TrackedButtons>
                </ButtonGroup>
                <SjekkPrisdrivendeVerdier value={watch()} onChange={onChange} />
            </form>
        );
    };

export default ProduktdetaljerSkjema;
