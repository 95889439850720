import {Ansatt} from '~/flyt/hovedflyt/tilbud/Tilbud';
import {formatDate, forsteINesteManed} from '~/utils/datoUtil';

const MIN_INDIVIDUELL_STILLINGSPROS_2_ANSATTE = 75;
const MIN_STILLINGSPROS_FOR_A_TELLES_MED_I_TOTALEN = 20;
const MIN_TOTAL_STILLINGSPROSENT_FLERE_ENN_2_ANSATTE = 200;
const DEFAULT_STARTDATO_NESTE_MANED = formatDate(forsteINesteManed());

export interface PaakrevdeVerdier {
    hoySats: number;
    lavSats: number;
    tilbudsdato: string;
    startdato: string;
}

export function finnInitiellePaakrevdeVerdier(): PaakrevdeVerdier {
    return {
        hoySats: 0,
        lavSats: 4,
        tilbudsdato: formatDate(new Date()),
        startdato: DEFAULT_STARTDATO_NESTE_MANED
    };
}

export const harNoenArslonnOver = (belop: number, ansatte: Ansatt[]): boolean => {
    return ansatte.some(ansatt => ansatt.lonn && ((ansatt.lonn * 100) / ansatt.stillingspros) > belop);
};

export const erOtpPliktig = (ansatte: Ansatt[]): boolean => {
    if (!ansatte || ansatte.length === 0) {
        return false;
    }

    const antallAnsatteMedStillingsprosMinimum75 = ansatte
        .filter(ansatt => ansatt.stillingspros >= MIN_INDIVIDUELL_STILLINGSPROS_2_ANSATTE)
        .length;

    if (antallAnsatteMedStillingsprosMinimum75 >= 2) {
        return true;
    }

    const antallIkkeEiereMedStillingsprosMinimum75 = ansatte
        .filter(ansatt => ansatt.eier === false && ansatt.stillingspros >= MIN_INDIVIDUELL_STILLINGSPROS_2_ANSATTE)
        .length;

    if (antallIkkeEiereMedStillingsprosMinimum75 >= 1) {
        return true;
    }

    const totalStillingsprosentOver20 = ansatte
        .filter(ansatt => ansatt.stillingspros >= MIN_STILLINGSPROS_FOR_A_TELLES_MED_I_TOTALEN)
        .map(ansatt => ansatt.stillingspros)
        .reduce((sum, ansattstillingspros) => sum + ansattstillingspros, 0);

    return totalStillingsprosentOver20 >= MIN_TOTAL_STILLINGSPROSENT_FLERE_ENN_2_ANSATTE;
};

export const visUfore = (ansatte: Ansatt[]) => {
    return ansatte.some(ansatt => ansatt.arbeidsdyktig === true);
}

export const visHoySats = (ansatte: Ansatt[], gverdi: number) => {
    return erOtpPliktig(ansatte) && harNoenArslonnOver(gverdi * 7.1, ansatte);
}
