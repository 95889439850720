import React from 'react';
import {Grid, GridCol, GridRow} from '@sb1/ffe-grid-react';
import {Heading1, Paragraph} from '@sb1/ffe-core-react';
import Produkter from '~/flyt/hovedflyt/kvittering/Produkter';
import {Skjemadata} from '@sparebank1/pensjon-bm-analytics';
import {useTrackPage} from '~/components/tracking/useTrackPage';
import useTilbud from '~/flyt/hovedflyt/tilbud/useTilbud';

const SKJEMA: Skjemadata = {navn: 'Kvittering', type: '', skjematype: 'KSMB'};
const steg = {
    TILBUD_SENDT: {navn: 'Tilbud sendt på epost', nummer: 1}
};

const EpostSendt: React.FunctionComponent = () => {

    useTrackPage(SKJEMA, steg.TILBUD_SENDT);

    const {tilbud} = useTilbud();
    return (
        <Grid>
            <GridRow className={'ksmb--padding-top'}>
                <GridCol center={true} className={'ksmb--flex-column'}>
                    <Heading1>E-post er sendt!</Heading1>
                    <Paragraph lead={true}>Det er sendt e-post til daglig leder eller innehaver med tilbud på følgende produkter</Paragraph>
                </GridCol>
                <GridCol>
                    <Produkter tilbud={tilbud} />
                </GridCol>
            </GridRow>
        </Grid>
    );
};

export default EpostSendt;
