import React, {useContext, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import Ansattinformasjon from '~/flyt/hovedflyt/ansatte/Ansattinformasjon';
import {ansattMedDefaultVerdier} from '~/flyt/hovedflyt/tilbud/Tilbud';
import {FormSubmitStatus, Skjemadata, trackFormStepStarted} from '@sparebank1/pensjon-bm-analytics';
import {FormContext} from '~/Routing';
import {oppdaterTilbud, opprettTilbud} from '~/flyt/hovedflyt/tilbud/TilbudService';
import {TilbudContext} from '~/flyt/hovedflyt/tilbud/TilbudContext';
import {useErrorHandler} from '~/components/felles/ErrorHandler';
import {GridCol} from '@sb1/ffe-grid-react';
import { Spinner } from '@sb1/ffe-spinner-react';
import {useTrackPage} from '~/components/tracking/useTrackPage';
import {useSearchParams} from "react-router";
import useOrgnr from "~/components/orgvelger/useOrgnr";

export const SKJEMA: Skjemadata = {navn: 'Ansattinformasjon', type: '', skjematype: 'KSMB'};
export const steg = {
    FYLL_INN_ANSATTE: {navn: 'Fyll inn ansatte', nummer: 1},
    SJEKK_DSF: {navn: 'Sjekk folkeregisteret', nummer: 2},
    OPPRETT_TILBUD: {navn: 'Opprett tilbud', nummer: 3},
    OPPDATER_TILBUD: {navn: 'Oppdater tilbud', nummer: 4}
};

type Params = 'orgnr' | 'antall';

const NyttTilbudAnsattinformasjon: React.FunctionComponent = () => {

    useTrackPage(SKJEMA, steg.FYLL_INN_ANSATTE);

    const [ searchParams ] = useSearchParams();
    const flytting = !!searchParams.get('flytting');
    const [beregner, setBeregner] = useState<boolean>(false);
    const {catchError} = useErrorHandler();
    const formContext = useContext(FormContext);
    const tilbudContext = useContext(TilbudContext);
    const {orgnr, antall} = useParams<Params>();
    const navigate = useNavigate();
    const {tilbud} = tilbudContext;
    useOrgnr(orgnr);

    if (!tilbud) {
        return <GridCol center={true}>
            <Spinner large={true} className="ksmb-spinner--lg" loadingText="Henter tilbud..."/>
        </GridCol>
    }

    const lagre = async () => {
        if (tilbudContext.tilbudId) {
            trackFormStepStarted(steg.OPPRETT_TILBUD);
            const {tilbudId} = tilbudContext;
            await oppdaterTilbud(tilbudId, tilbud);
            return tilbudId;
        } else {
            trackFormStepStarted(steg.OPPDATER_TILBUD);
            const tilbudId = await opprettTilbud(tilbud);
            tilbudContext.setTilbudId(`${tilbudId}`);
            return tilbudId;
        }
    }

    const onFinish = async () => {
        try {
            const tilbudId = await lagre();
            formContext.setFormStatus(FormSubmitStatus.SUCCESS);
            formContext.setFerdig(true);
            navigate(`/produkt/${tilbudId}?flytting=${flytting}`);
        } catch(error: any) {
            setBeregner(false);
            catchError(error);
        }
    };

    tilbud.ansatte = tilbud.ansatte.length ? tilbud.ansatte : new Array(Number(antall)).fill(ansattMedDefaultVerdier);

    return <Ansattinformasjon knappetekst="Beregn pris" steg={steg.SJEKK_DSF} tilbud={tilbud} beregner={beregner} setBeregner={setBeregner} onFinish={onFinish} flytting={flytting}/>
}
export default NyttTilbudAnsattinformasjon;
