import {Input, InputGroup} from "@sb1/ffe-form-react";
import React from "react";

interface Props {
    label?: string;
    epost: string;
    setEpost: (_:string) => void;
    fieldMessage?: string;
}

const Epost: React.FunctionComponent<Props> = ({label, epost, setEpost, fieldMessage}) => {
    return <InputGroup label={label ?? "E-post"} fieldMessage={fieldMessage}>
        <Input
            value={epost}
            onChange={e => setEpost(e.target.value)}
            className="ksmb-input-field ksmb-input-field--xl"
            autoComplete="on"
        />
    </InputGroup>

}
export default Epost;