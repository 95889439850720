import React, {ReactElement, useEffect, useState} from "react";
import {Skjemadata, trackFormStepStarted} from "@sparebank1/pensjon-bm-analytics";
import {useTrackPage} from "~/components/tracking/useTrackPage";
import {harSigneringsrett} from "~/flyt/hovedflyt/tilbud/TilbudService";
import {Grid, GridCol, GridRow} from "@sb1/ffe-grid-react";
import {Spinner} from "@sb1/ffe-spinner-react";

const SKJEMA: Skjemadata = { navn: 'Signering', type: '', skjematype: 'KSMB' };
const steg = {
    SIGNERINGSFLYT: { navn: 'Signeringsflyt', nummer: 1 },
    SJEKK_SIGNERINGSRETT: { navn: 'Sjekk om kunden har rett til å signere', nummer: 2 },
    MANGLER_SIGNERINGSRETT: { navn: 'Kunden mangler rett til å signere', nummer: 3 },
};

interface Props {
    tilbudId: string;
    signeringsrett: ReactElement | string;
    ikkeSigneringsrett: ReactElement | string;
}

const Signeringsrett: React.FunctionComponent<Props> = ({tilbudId, signeringsrett, ikkeSigneringsrett}) => {
    const [ signeringsrettSjekket, setSigneringsrettSjekket ] = useState(false);
    const [ signeringsrettOk, setSigneringsrettOk ] = useState(false);

    useTrackPage(SKJEMA, steg.SIGNERINGSFLYT);

    useEffect(() => {
        trackFormStepStarted(steg.SJEKK_SIGNERINGSRETT);
        harSigneringsrett(tilbudId).then(() => {
            setSigneringsrettOk(true);
        }).catch(() => {
            trackFormStepStarted(steg.MANGLER_SIGNERINGSRETT);
            setSigneringsrettOk(false);
        }).finally(() => {
            setSigneringsrettSjekket(true)
        });
    }, [tilbudId]);

    if (!signeringsrettSjekket) {
        return <Grid>
            <GridRow>
                <GridCol center={true}>
                    <Spinner large={true} loadingText="Sjekker signeringsrett" />
                </GridCol>
            </GridRow>
        </Grid>
    }

    const vis = (target: ReactElement | string) => {
        if (typeof target === 'string') {
            window.location.href = target;
            return <Spinner />
        } else {
            return target;
        }
    }
    return vis(signeringsrettOk ? signeringsrett : ikkeSigneringsrett);
}
export default Signeringsrett;