import React, {useContext, useEffect, useState} from 'react';
import {Heading1, Heading2, Paragraph} from '@sb1/ffe-core-react';
import {InputGroup, Tooltip} from '@sb1/ffe-form-react';
import {Dropdown} from '@sb1/ffe-dropdown-react';
import {ButtonGroup, PrimaryButton, SecondaryButton} from '@sb1/ffe-buttons-react';
import {Grid, GridCol, GridRow} from '@sb1/ffe-grid-react';
import {useErrorHandler} from '~/components/felles/ErrorHandler';
import {FormSubmitStatus, trackFormStepStarted} from '@sparebank1/pensjon-bm-analytics';
import Steg1 from '~/flyt/hovedflyt/steg1/Steg1';
import TrackedButtons from '~/components/tracking/TrackedButtons';
import {maksAntallAnsatte} from "~/App";
import {useForm} from "react-hook-form";
import {requiredMelding} from "~/flyt/hovedflyt/produkt/validering";
import RadioSwitchJaNei from "~/components/felles/RadioSwitchJaNei";
import {Hjelpetekst} from "~/flyt/hovedflyt/produkt/Hjelpetekst";
import * as hjelpetekst from '~/flyt/hovedflyt/produkt/hjelpetekster';
import {FormContext, tilAnsatte} from "~/Routing";
import {useNavigate, useParams} from "react-router";
import useOrgnr from "~/components/orgvelger/useOrgnr";
import {Spinner} from "@sb1/ffe-spinner-react";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {ContextMessage} from "@sb1/ffe-messages-react";

const steg = {
    ANTALL_ANSATTE: {navn: 'Antall ansatte', nummer: 3}
}

const validering = z.object({
    antallAnsatte: z.string().refine(value => value !== 'ikkevalgt', 'Antall ansatte i bedriften må oppgis'),
    flytting: z.boolean({required_error: requiredMelding}),
    ufore: z.boolean().optional()
}).superRefine(({flytting, ufore}, ctx) => {
    if (flytting === true && ufore === undefined) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['ufore'],
            message: requiredMelding
        });
    }
})

type FormInput = z.infer<typeof validering>;

type Params = 'orgnr';

const AntallAnsatte: React.FunctionComponent = () => {
    const [visHjelpFlytting, setVisHjelpFlytting] = useState<boolean>(false);
    const { setFeilmelding } = useErrorHandler();
    const navigate = useNavigate();
    const formContext = useContext(FormContext);
    const {orgnr} = useParams<Params>();
    const {org, error} = useOrgnr(orgnr, true);

    const { handleSubmit, formState: { errors }, watch, setValue } = useForm<FormInput>({
        resolver: zodResolver(validering),
        defaultValues: {antallAnsatte: 'ikkevalgt'}
    });

    const flytting = watch('flytting');
    const antallAnsatte = watch('antallAnsatte');
    const ufore = watch('ufore');

    useEffect(() => {
        trackFormStepStarted(steg.ANTALL_ANSATTE);
    }, []);

    useEffect(() => {
        if (ufore) {
            setFeilmelding(`Vi kan dessverre ikke gi pris på nett når bedriften har uføre på avtalen. Vi hjelper gjerne din bedrift med spørsmål og kjøp av pensjon per telefon. Gi oss din kontaktinformasjon så vil en av våre rådgivere ta kontakt.`);
        }
    }, [ufore, setFeilmelding]);

    useEffect(() => {
        if (antallAnsatte === 'formange') {
            setFeilmelding(`Denne løsningen kan ikke beregne pris for bedrifter over ${maksAntallAnsatte} ansatte. Vi hjelper gjerne din bedrift med spørsmål og kjøp av pensjon per telefon. Gi oss din kontaktinformasjon så vil en av våre rådgivere ta kontakt.`);
        }
    }, [antallAnsatte, setFeilmelding]);

    function sjekkPris(ansatte: string, flytting: boolean) {
        formContext.setFormStatus(FormSubmitStatus.SUCCESS);
        formContext.setFerdig(true);
        navigate(tilAnsatte(orgnr, ansatte, flytting));
    }

    if (error) {
        return <Steg1 />
    }

    return <Grid className="ksmb-steg-1">
        <GridRow>
            <GridCol center={true} className='ksmb-grid-col--padding-bottom'>
                <Heading1 className="ksmb-header--center">Innskuddspensjon</Heading1>
            </GridCol>
        </GridRow>
        <GridRow>
            <GridCol lg={{offset: 3, cols: 6}} className='ksmb-grid-col--padding-bottom'>
                <Paragraph lead={true} className="ffe-body-paragraph--text-center">
                    For å gi tilbud må vi vite om et par ting om bedriften.
                </Paragraph>
            </GridCol>
        </GridRow>
        <GridRow>
            {org ?
                <GridCol lg={{offset:2, cols: 6}} md={{offset: 2, cols: 8}}>
                    <Heading2 lookLike={3}>{org.navn} {org.orgnr}</Heading2>
                    <form onSubmit={handleSubmit(({antallAnsatte, flytting}) => sjekkPris(antallAnsatte, flytting))}>
                        <InputGroup
                            label="Hvor mange personer er det i bedriften?"
                            fieldMessage={errors.antallAnsatte?.message}
                        >
                            <Dropdown
                                defaultValue={antallAnsatte}
                                className="ksmb-input-field ksmb-input-field--fit-to-content"
                                onChange={e => setValue('antallAnsatte', e.target.value, {shouldValidate: true})}
                                autoFocus={true}
                            >
                                <option value="ikkevalgt"></option>
                                {new Array(maksAntallAnsatte).fill(0).map((_, index) =>
                                    <option key={index} value={index + 1}>{index + 1}</option>)}
                                <option value="formange">{maksAntallAnsatte + 1} eller flere</option>
                            </Dropdown>
                        </InputGroup>

                        { org.enkeltpersonsforetak ?
                            <ContextMessage type={"info"} compact={true}>
                                Husk å inkludere eier og eventuelt eiers ektefelle i antall ansatte for å få riktig pris.
                            </ContextMessage>
                            : null
                        }
                        <RadioSwitchJaNei
                            name="flytting"
                            selectedValue={flytting}
                            setValue={setValue}
                            label="Skal dere flytte pensjonsavtale fra et annet selskap?"
                            fieldMessage={errors?.flytting?.message}
                            tooltip={<Tooltip onClick={() => setVisHjelpFlytting(!visHjelpFlytting)}></Tooltip>}
                        >
                            <Hjelpetekst vis={visHjelpFlytting} tekst={hjelpetekst.flytting} />
                        </RadioSwitchJaNei>

                        {flytting ?
                            <RadioSwitchJaNei
                                name="ufore"
                                selectedValue={ufore}
                                setValue={setValue}
                                label="Er det ansatte i pensjonsavtalen som er helt uføre, delvis uføre eller langtidssykemeldte?"
                                fieldMessage={errors?.ufore?.message}
                            />
                            : null
                        }
                        <ButtonGroup className="ksmb-button-group--md" ariaLabel="Knapper for å gå videre eller velge annen bedrift">
                            <TrackedButtons steg={steg.ANTALL_ANSATTE.navn} knappNavn={['nytt sok', 'sjekk pris']}>
                                <PrimaryButton type="submit">
                                    Gå videre
                                </PrimaryButton>
                                <SecondaryButton type="button" onClick={() => navigate('/')}>
                                    Velg annen bedrift
                                </SecondaryButton>
                            </TrackedButtons>
                        </ButtonGroup>
                    </form>
                </GridCol>
                :
                <GridCol center={true}>
                    <Spinner large={true} className="ksmb-spinner--lg" loadingText="Henter informasjon om bedriften..." />
                </GridCol>
            }
        </GridRow>
    </Grid>
};
export default AntallAnsatte;
