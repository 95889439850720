import React, {useEffect, useState} from "react";
import {Input, InputGroup} from "@sb1/ffe-form-react";
import {Dropdown} from "@sb1/ffe-dropdown-react";
import {useSettings} from "~/components/felles/Settings";

interface Props {
    flyttesFraSelskap?: string;
    setFlyttesFraSelskap: (_:string) => void;
    fieldMessage?: string;
}

export const ANNET_SELSKAP = '-1';

const FlyttesFraSelskap: React.FunctionComponent<Props> = ({flyttesFraSelskap, setFlyttesFraSelskap, fieldMessage}) => {
    const { flytteFraSelskaper } = useSettings();
    const defaultFlyttesFraSelskapId =
        flyttesFraSelskap ? flytteFraSelskaper.find((selskap) => selskap.navn === flyttesFraSelskap)?.id ?? ANNET_SELSKAP : "";
    const defaultFlyttesFraSelskapEgendefinert = defaultFlyttesFraSelskapId == ANNET_SELSKAP ? flyttesFraSelskap ?? "" : "";
    const [ flyttesFraSelskapId, setFlyttesFraSelskapId ] = useState(defaultFlyttesFraSelskapId);
    const [ flyttesFraSelskapEgendefinert, setFlyttesFraSelskapEgendefinert ] = useState(defaultFlyttesFraSelskapEgendefinert);

    useEffect(() => {
        if (flyttesFraSelskapId === ANNET_SELSKAP && flyttesFraSelskapEgendefinert !== flyttesFraSelskap) {
            setFlyttesFraSelskap(flyttesFraSelskapEgendefinert);
        } else if (flyttesFraSelskapId !== ANNET_SELSKAP && flyttesFraSelskapId) {
            const navn = flytteFraSelskaper.find((selskap) => selskap.id === flyttesFraSelskapId)?.navn ?? "";
            if (navn !== flyttesFraSelskap) {
                setFlyttesFraSelskap(navn);
            }
        }
    }, [flyttesFraSelskapId, flyttesFraSelskapEgendefinert, setFlyttesFraSelskap, flyttesFraSelskap, flytteFraSelskaper]);

    return <>
        <InputGroup
            label="I hvilket selskap har bedriften pensjonsavtale i dag?"
            fieldMessage={flyttesFraSelskapId !== ANNET_SELSKAP ? fieldMessage : undefined}>
            <Dropdown
                name="flytteSelskapId"
                onChange={e => setFlyttesFraSelskapId(e.target.value)}
                defaultValue={defaultFlyttesFraSelskapId}
                className="ksmb-input-field ksmb-input-field--lg ksmb-produktspesifikasjon-input-group--extra-margin-top"
            >
                { flytteFraSelskaper.map((selskap, key) =>
                    <option key={key} value={selskap.id} disabled={selskap.id === ""}>{selskap.navn}</option>
                )}
            </Dropdown>
        </InputGroup>
        {flyttesFraSelskapId === ANNET_SELSKAP &&
            <InputGroup
                label="Navn på selskap"
                fieldMessage={flyttesFraSelskapId === ANNET_SELSKAP ? fieldMessage : undefined}>
                <Input
                    defaultValue={defaultFlyttesFraSelskapEgendefinert}
                    onChange={e => setFlyttesFraSelskapEgendefinert(e.target.value) }
                    className="ksmb-input-field ksmb-input-field--lg"
                />
            </InputGroup>
        }
    </>
}
export default FlyttesFraSelskap;