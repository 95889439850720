import {useContext, useEffect} from "react";
import {Org, orgSjekk} from "./OrgSokService";
import {initTilbud} from "~/flyt/hovedflyt/steg1/Steg1";
import {useSettings} from "~/components/felles/Settings";
import {TilbudContext} from "~/flyt/hovedflyt/tilbud/TilbudContext";
import {setLogOrgnr} from "~/utils/fetch";
import {useQuery} from "@tanstack/react-query";
import {useErrorHandler} from "~/components/felles/ErrorHandler";

const useOrgnr = (orgnr?: string, nyttTilbud?: boolean) => {
    const { catchError } = useErrorHandler();
    const { data: org, error, isLoading } = useQuery<Org>({
        queryKey: ['org', orgnr],
        queryFn: () => orgSjekk(orgnr as string, !!nyttTilbud),
        enabled: !!orgnr
    });
    const { omkostningsmodell } = useSettings();
    const tilbudContext = useContext(TilbudContext);

    if (error) {
        catchError(error);
    }
    setLogOrgnr(orgnr);

    useEffect(() => {
        if (org && org.orgnr !== tilbudContext.tilbud?.orgnr) {
            tilbudContext.setTilbud(initTilbud(org, omkostningsmodell));
        }
    }, [org, omkostningsmodell, tilbudContext]);

    return {org, error, isLoading};
}
export default useOrgnr;
