import React, {useEffect} from 'react';
import {ProduktdetaljerInput} from '~/flyt/hovedflyt/produkt/ProduktdetaljerSkjema';

interface Props {
    onChange: (value: ProduktdetaljerInput) => void;
    value: ProduktdetaljerInput;
}

export const SjekkPrisdrivendeVerdier: React.FunctionComponent<Props> = ({onChange, value}) => {
    useEffect(() => {
        onChange(value);
    }, [value.lavSats, value.hoySats, value.uforeDekning, value.startdato]); //eslint-disable-line react-hooks/exhaustive-deps
    return null;
};
