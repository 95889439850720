import React, {useContext, useState} from 'react';
import OrgSok from './OrgSok';
import {Org, orgSok} from './OrgSokService';
import OrgListe from './OrgListe';
import {useErrorHandler} from '~/components/felles/ErrorHandler';
import {FormSubmitStatus, Skjemadata} from '@sparebank1/pensjon-bm-analytics';
import {FormContext} from '~/Routing';
import useSkjemafeil from '~/components/tracking/useSkjemafeil';

export const SKJEMA: Skjemadata = {navn: 'Kjøp pensjon SMB', type: '', skjematype: 'KSMB'};
export const steg = {
    SOK_BEDRIFT: {navn: 'Søk bedrift', nummer: 1},
    VELG_BEDRIFT: {navn: 'Velg bedrift', nummer: 2}
};

interface Props {
    velgOrgnr: (orgnr: string) => void;
}

const OrgVelger: React.FunctionComponent<Props> = ({velgOrgnr}) => {

    const [result, setResult] = useState<Org[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const errorHandler = useErrorHandler();
    const formContext = useContext(FormContext);
    const [feilmelding, setFeilmelding] = useSkjemafeil('orgSok');

    const executeSok = async (query: string) => {
        if (query) {
            setFeilmelding('');
            errorHandler.setFeilmelding(null);
            setLoading(true);
            try {
                const resultRespons: Org[] = await orgSok(query);
                if (resultRespons.length === 1) {
                    velgOrgnr(resultRespons[0].orgnr);
                } else if (resultRespons.length === 0) {
                    setFeilmelding('Fant ingen bedrifter med dette navnet. Skriv inn bedriftsnavn eller organisasjonsnummer.');
                }
                setResult(resultRespons);
            } catch(error: any) {
                setResult([]);
                if (error.status !== 404 && error.details.melding) {
                    setFeilmelding(error.details.melding);
                } else {
                    formContext.setFormStatus(FormSubmitStatus.ERROR);
                    errorHandler.catchError(error);
                }
            } finally {
                setLoading(false);
            }
        } else {
            setFeilmelding('Oppgi organisasjonsnummer eller bedriftsnavn');
        }
    }

    function nyttSok() {
        setResult([]);
    }

    return <>
        {result.length == 0 || errorHandler.feilmelding ?
            <OrgSok feilmelding={feilmelding} onClick={executeSok} isLoading={loading}/>
            :
            <OrgListe data={result} nyttSok={nyttSok} setOrgnr={velgOrgnr}/>
        }
    </>
};

export default OrgVelger;
