import {useParams} from "react-router";
import React from "react";
import Signeringsrett from "~/components/signering/Signeringsrett";
import {useSettings} from "~/components/felles/Settings";
import ManglerSigneringsrett from "./ManglerSigneringsrett";
import useOrgnr from "~/components/orgvelger/useOrgnr";
import {Spinner} from "@sb1/ffe-spinner-react";
import Innlogget from "~/components/felles/Innlogget";

type Params = 'orgnr' | "tilbudId";


const Signering: React.FunctionComponent = () => {
    const { kfb_url } = useSettings();
    const {orgnr, tilbudId} = useParams<Params>();
    const {org} = useOrgnr(orgnr);

    if (!org) {
        return <Spinner />
    }

    return <Innlogget>
        <Signeringsrett
            tilbudId={tilbudId as string}
            signeringsrett={`${kfb_url}/kfb/frontend/kundeklient/${orgnr}/tilbud/signering/${tilbudId}/innhenting/detaljer`}
            ikkeSigneringsrett={<ManglerSigneringsrett org={org} tilbudId={tilbudId as string} />}
        />
    </Innlogget>
}
export default Signering;