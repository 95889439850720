import React, {useState} from "react";
import {Heading2} from "@sb1/ffe-core-react";
import {GridCol, GridColProps, GridRow} from "@sb1/ffe-grid-react";
import {Org} from "~/components/orgvelger/OrgSokService";
import {ContextMessage} from "@sb1/ffe-messages-react";
import {z} from "zod";
import epost from "~/components/validering/epost";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import Epost from "~/components/felles/chat-window/Epost";
import {ActionButton, ButtonGroup} from "@sb1/ffe-buttons-react";
import TrackedButtons from "~/components/tracking/TrackedButtons";
import {post} from "~/utils/fetch";
import {useErrorHandler} from "~/components/felles/ErrorHandler";

interface Props {
    org: Org;
    tilbudId: string;
}

const validering = z.object({
    epost: epost(true),
})

type FormInput = z.infer<typeof validering>;

const ManglerSigneringsrett: React.FunctionComponent<Props> = ({org, tilbudId}) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ suksess, setSuksess ] = useState<string>();
    const {catchError} = useErrorHandler();

    const { handleSubmit, formState: { errors, isSubmitted }, watch, setValue } = useForm<FormInput>({
        resolver: zodResolver(validering),
        defaultValues: {epost: ''}
    });

    const sendEpost = async ({epost}) => {
        try {
            setIsLoading(true);
            await post("/api/infofullmakt/send_epost", {epost, tilbudId});
            setSuksess(`E-post med lenke til fullmakten ble sendt til ${epost}`);
        } catch (error: any) {
            catchError(error);
        } finally {
            setIsLoading(false);
        }
    }

    const colsize: GridColProps = {lg: {offset:2, cols: 6}, md: {offset: 2, cols: 8}}

    return <>
        <GridRow padding={"lg"}>
            <GridCol {...colsize} >
                <Heading2 lookLike={3}>{org.navn} {org.orgnr}</Heading2>
                <ContextMessage type={"error"}>
                    Det ser ikke ut som du har signaturrett for bedriften.
                    Oppgi e-post til daglig leder eller innehaver så vil de bli informert om fullmakten.
                    De kan logge inn i bedriftsportalen for å signere den.
                </ContextMessage>
            </GridCol>
        </GridRow>
        <GridRow>
            <GridCol {...colsize}>
                <form onSubmit={handleSubmit(sendEpost)}>
                    <Epost
                        epost={watch("epost")}
                        setEpost={value => setValue("epost", value, {shouldValidate: isSubmitted})}
                        fieldMessage={errors.epost?.message}
                    />
                    {suksess ?
                        <ContextMessage type={"success"} >
                            {suksess}
                        </ContextMessage>
                        :
                        <ButtonGroup ariaLabel="Knappegruppe" thin={true}>
                            <TrackedButtons knappNavn={'send_epost'}>
                                <ActionButton type="submit" isLoading={isLoading}>
                                    Send e-post
                                </ActionButton>
                            </TrackedButtons>
                        </ButtonGroup>
                    }
                </form>
            </GridCol>
        </GridRow>
    </>
}

export default ManglerSigneringsrett;