import { HjelpetekstProps } from '~/flyt/hovedflyt/produkt/Hjelpetekst';

export const lavSats: HjelpetekstProps = {
    tittel: 'Spareprosent',
    // tslint:disable-next-line: max-line-length
    innhold: ['Bedriften betaler et innskudd for alle sine ansatte som omfattes av pensjonsordningen. Spareprosenten gjelder for lønn opp til 12 G og minimumskravet er 2 %.'],
    visG: true
};

export const hoySats = (innslagspunkt: string): HjelpetekstProps => {
    return ({
        tittel: 'Økt sparing for ansatte med høyere lønn',
        // tslint:disable-next-line: max-line-length
        innhold: [`Folketrygden gir ikke pensjonsopptjening for lønnsinntekter utover 7, 1G (ca. ${innslagspunkt}). Ansatte med høyere lønn vil derfor oppleve en betydelig inntektsreduksjon som pensjonister. Tilleggssparing for lønn som overstiger 7, 1G hjelper derfor til med å kompensere for den manglende utbetalingen fra Folketrygden.`],
        visG: true
    });
};

export const uforeDekning: HjelpetekstProps = {
    tittel: 'Uførepensjon',
    // tslint:disable-next-line: max-line-length
    innhold: ['Ved langvarig sykemelding dekker ikke offentlig trygd den ansattes vanlige inntekt. Hvis en av bedriftens medarbeidere mister arbeidsevnen uten uførepensjon vil det gå utover den ansattes økonomi. Med uførepensjon kan din medarbeider få månedlige utbetalinger som et tillegg til folketrygden. Uføreforsikring minsker gapet mellom opprinnelig inntekt og trygd.'],
    visG: false
};

export const flytting: HjelpetekstProps = {
    // tslint:disable-next-line: max-line-length
    innhold: ['Dersom bedriften har pensjonsavtale i et annet selskap, kan vi hjelpe med å flytte eksisterende avtale. Prosessen kan ta opp mot 3 måneder.'],
    visG: false
};

export const startdato: HjelpetekstProps = {
    tittel: 'Startdato',
    // tslint:disable-next-line: max-line-length
    innhold: ['Sparingen gjelder fra den 1. i hver måned. Du kan velge å endre startdato for sparing til den 1. i en tidligere måned hvis sparingen skal gjelde fra en dato som er tilbake i tid.'],
    visG: false
};

export const infoTekstDagligLeder = 'For å kjøpe produktene må du logge inn med BankID. Slik bekrefter vi din rolle i bedriften mot Brønnøysundregistrene.';

// tslint:disable-next-line: max-line-length
export const infoTekstIkkeDagligLeder = 'Det er kun daglig leder, innehaver eller styreleder med rettighetshavere i bedriften som kan signere tilbudet. Du kan fortsette og lage en bestilling og deretter kan overnevnte fullføre kjøpet via e-post.';
