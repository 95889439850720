import React from "react";
import {Route, Routes, useNavigate} from "react-router";
import {Grid, GridCol, GridRow} from "@sb1/ffe-grid-react";
import {Heading1, Paragraph} from "@sb1/ffe-core-react";
import OrgVelger from "~/components/orgvelger/OrgVelger";
import Bestill from "~/flyt/infofullmakt/Bestill";
import Signering from "~/flyt/infofullmakt/Signering";

const Infofullmaktflyt: React.FunctionComponent = () => {

    const navigate = useNavigate();

    const velgOrgnr = (orgnr: string) => {
        navigate(`/infofullmakt/${orgnr}`);
    }

    return <Grid>
        <GridRow padding={"xl"}>
            <GridCol centerText={true}>
                <Heading1>Flytte pensjonsavtalen til oss?</Heading1>
            </GridCol>
        </GridRow>
        <GridRow>
            <GridCol centerText={true} lg={{offset: 3, cols: 6}}>
                <Paragraph lead={true}>
                    La oss hente informasjon fra dagens leverandør, så kan vi gi deg et uforpliktende tilbud på pensjonsavtale hos oss.
                </Paragraph>
            </GridCol>
        </GridRow>
        <Routes>
            <Route path={'/'} element={<OrgVelger velgOrgnr={velgOrgnr} />}/>
            <Route path={'/:orgnr'} element={<Bestill />} />
            <Route path={'/signering/:orgnr/:tilbudId'} element={<Signering />} />
        </Routes>
    </Grid>
}
export default Infofullmaktflyt;