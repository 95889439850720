import React from 'react';
import {useForm} from 'react-hook-form';
import {Grid, GridCol, GridRow} from '@sb1/ffe-grid-react';
import {Heading2, Paragraph} from '@sb1/ffe-core-react';
import {ActionButton, ButtonGroup} from '@sb1/ffe-buttons-react';
import {Tilbud} from '~/flyt/hovedflyt/tilbud/Tilbud';
import epost from "~/components/validering/epost";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import Epost from "~/components/felles/chat-window/Epost";

export interface Params {
    loading: boolean;
    tilbud: Tilbud;
    onSubmit: (values: EpostInput) => void;
}

export const valideringInput = z.object({
    epost: epost(true)
});

export type EpostInput = z.infer<typeof valideringInput>;

const SendEpost: React.FunctionComponent<Params> =
    ({ loading, onSubmit }) => {

        const { watch, setValue, handleSubmit, formState: { errors, isSubmitted } } = useForm<EpostInput>({
            resolver: zodResolver(valideringInput),
            defaultValues: { epost: '' }
        });

        return (
            <Grid>
                <GridRow>
                    <GridCol lg={{ cols: 8, offset: 2 }}>
                        <Heading2>Send tilbudet på e-post</Heading2>
                        <Paragraph>
                            Oppgi e-post til daglig leder eller innehaver så sendes en lenke til tilbudet slik at kjøpet kan fullføres.
                        </Paragraph>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Epost
                                epost={watch("epost")}
                                setEpost={value => setValue("epost", value, {shouldValidate: isSubmitted})}
                                fieldMessage={errors.epost?.message}
                            />
                            <ButtonGroup ariaLabel="Knapp for å sende tilbud på e-post">
                                <ActionButton type="submit"
                                    isLoading={loading}
                                    ariaLoadingMessage="Vennligst vent">
                                    Send e-post
                                </ActionButton>
                            </ButtonGroup>
                        </form>
                    </GridCol>
                </GridRow>
            </Grid>
        );
    };

export default SendEpost;
